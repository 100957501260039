import React, { Component } from "react"
import NewLayout from '../layout/newLayout'
import Title from "../components/title"
import styles from "../styles/scss/pages/404.module.scss"
import cn from "classnames"
import { Link } from "gatsby"

export default class NotFoundPage extends Component {

  state = { minHeight: 0 }

  updateDimensions() {
    const minHeight = window.innerWidth * 894 / 1360;
    this.setState({ minHeight });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const { minHeight } = this.state;
    var divStyle = {
      height: this.state.minHeight
    }
    return (
      <NewLayout>
        <div className={`${styles.heroImage} ${styles.hero}`} style={{ minHeight, divStyle }}>
          <div className={cn("container-fluid", "h-100")}>
            <div className={cn("row", "h-100")}>
              <div className={cn("col", "col-4", "col-md-6")}>

              </div>
              <div className={cn("col", "col-8", "col-md-6", "h-100", "d-flex", "justify-content-center")}>
                <div className={cn("justify-content-center align-self-center", styles.content)}>
                  <div><Title value="404" className={cn(styles.title)} /></div>
                  <div><Title value="We're not sure what happened, but we've got our best people on it" className={cn(styles.subTitle)} /></div>
                  <hr className={cn(styles.divider)} />
                  <div className={cn(styles.message)}>
                    In the meantime, check out some of our most popular pages and helpful content.
                  </div>
                  <div className={cn("row", styles.links)}>
                    <div className={cn("col")}><Link to="/">Home &raquo;</Link></div>
                    <div className={cn("col")}><Link to="/products/">Products &raquo;</Link></div>
                    <div className={cn("col")}><Link to="/how-to/">How To &raquo;</Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NewLayout>
    );
  }

}
